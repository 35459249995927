<template>

  <div class="container">
    <b-row class="my-2 w-100">
      <h6>{{ inputType == 'phoneNumber' ? 'Phone Number': 'Email Address' }}</h6>
      <b-input-group
        v-if="inputType == 'phoneNumber'"
      >
        <b-form-input
          id="phone_number"
          v-model="phoneNumber"
          :disabled="counting"
          variant="outline-warning"
          :number="false"
          @input="validateNumber"
        />
        <b-input-group-append>
          <b-button
            variant="outline-primary"
            :disabled="counting"
            @click="sendVerificationCode('phone_number')"
          >
            <vue-countdown
              v-if="counting"
              v-slot="{ totalSeconds }"
              :time="120000"
              @end="onCountdownEnd"
            >
              {{ totalSeconds }} s
            </vue-countdown>
            <span v-else>{{ verificationText }}</span>
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group v-if="inputType == 'email'">
        <b-form-input
          id="email"
          v-model="userEmail"
          disabled
        />
        <b-input-group-append>
          <b-button
            variant="warning"
            :disabled="counting"
            @click="sendVerificationCode('email_address')"
          >
            <vue-countdown
              v-if="counting"
              v-slot="{ totalSeconds }"
              :time="120000"
              @end="onCountdownEnd"
            >
              {{ totalSeconds }} s
            </vue-countdown>
            <span v-else>{{ verificationText }}</span>
          </b-button>
        </b-input-group-append>

      </b-input-group>

    </b-row>
    <b-row class="mt-2 mb-3 w-100">
      <div class="w-100">
        <b-form-input
          v-model="verificationCode"
          number
          type="number"
          max="999999"
          debounce="1000"
          placeholder="Enter the verification code you have received."
          :state="verificationCode && verificationCode.toString().length === 6"
          @input="validateNumber"
        />
        <b-form-invalid-feedback
          tooltip
          style="margin-top: -50px;margin-left: 15px;"
        >
          Your verification code must be exactly 6 digits
        </b-form-invalid-feedback>
      </div>

      <div class="w-100 mt-1">
        <SpinnerBtn v-if="isLoading" />
        <b-button
          v-else
          block
          class="mx-auto"
          variant="primary"
          @click="verifySentCode"
        >
          Verify Code
        </b-button>
      </div>
    </b-row>
  </div>

</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    VueCountdown,
  },
  props: {
    inputType: { type: String, default: 'phoneNumber' },

  },
  data() {
    return {
      isLoading: false,
      popup: false,
      counting: false,
      state: false,
      message: '',
      destinationType: null,
      phoneNumber: null,
      userEmail: null,
      verificationCode: null,
      verificationText: 'Request Verification Code',
    }
  },
  watch: {

  },
  created() {
    const currentUser = this.$store.state.auth.ActiveUser
    this.phoneNumber = currentUser.phone_number
    this.userEmail = currentUser.email_address
    if (this.inputType === 'phoneNumber') {
      this.validateNumber()
      this.destinationType = 'phone_number'
    } else {
      this.destinationType = 'email_address'
    }
  },
  methods: {
    verifySentCode() {
      this.isLoading = true
      if (this.verificationCode.toString().length > 5) {
        this.$http
          .get(`/api/send-verification/${this.verificationCode}/${this.destinationType}`)
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Verification Alert',
                    icon: 'AlertCircleIcon',
                    text: `Your ${this.destinationType === 'email_address' ? 'email address' : 'phone number'} has been verified successfully.`,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$emit('close-verification', this.phoneNumber)
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Verification Alert',
                    icon: 'AlertCircleIcon',
                    text: 'Invalid or expired verification code.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Verification Alert',
                  icon: 'AlertCircleIcon',
                  text: 'Could not verify your phone number, try again later.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
      }
    },
    validateNumber() {
      this.state = this.$checkPhoneNumber(this.phoneNumber.toString()).state
      this.message = this.$checkPhoneNumber(this.phoneNumber.toString()).message
    },
    onCountdownEnd() {
      this.counting = false
    },
    sendVerificationCode(destinationType) {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      let phoneNumberVerification = false
      let emailVerification = false

      let successMessage = ''
      
      if (destinationType === 'phone_number') {
        phoneNumberVerification = true
        successMessage = 'Your verification code has been sent to your phone number.'
      }
      if (destinationType === 'email_address') {
        emailVerification = true
        successMessage = 'Your verification code has been sent to your email address.'
      }
      this.destinationType = destinationType
      this.counting = true
      this.$http
        .post('/api/send-verification', {
          data: {
            phone_number: this.phoneNumber,
            email_address: this.userEmail,
            phone_verification: phoneNumberVerification,
            email_verification: emailVerification,
          },
        })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Verification Alert',
                  icon: 'AlertCircleIcon',
                  text: successMessage,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Verification Alert',
                  icon: 'AlertCircleIcon',
                  text: 'Could not send the verification code, try again later.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Verification Alert',
                icon: 'AlertCircleIcon',
                text: 'Could not send the verification code, try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },
}
</script>
<style scoped>

.form-control{
  height: 40px!important;
}
</style>
